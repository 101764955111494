@import url('https://fonts.googleapis.com/css2?family=Yeon+Sung&display=swap');
.list{
    color: white;
    width: 400px;
    background-color: blueviolet;
    height: 40px;
    margin: 20px auto;
    border-radius: 5px;
}
.list p{
    padding: 10px;
    position: relative;

}
.list p span{
    position: absolute;
    right: 10px;
    cursor: pointer;
}
.faicons{
    margin: 0 5px;
    font-size: 15px;
}
.list p input{
    background-color: transparent;
    border: 0;
    color: white;
    font-weight: bolder;
    font-size: 20px;
    font-family: 'Yeon Sung', cursive;
}
.list p input:focus{
    outline: none;
}