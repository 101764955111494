@import url('https://fonts.googleapis.com/css2?family=Yeon+Sung&display=swap');
body {
  background-color: white;
  /* background-image: url('./img/back.jpg'); */

}

.App{
  /* background-color: #3f3160; */
  background-color: white;
  min-height: 500px;
  width: 500px;
  margin: 40px auto;
  border: 3px solid blueviolet;
  border-radius: 20px;
}

h2{
  text-align: center;
  font-family: 'Yeon Sung', cursive;
  color: blueviolet;
  font-size:30px;
}

#to-do-form input{
  /* background-color: rgb(27, 112,137); */
  background-color: white;
  border: 0;
  width: 300px;
  height: 50px;
  padding: 0 20px;
  margin: 20px;
  font-size: 19px;
  border-radius:10px;
  /* color: #fff; */
  color: black;
  border: 3px solid blueviolet;
  font-family: 'Yeon Sung', cursive;
}

#to-do-form input::placeholder{
  color: black;
  font-family: 'Yeon Sung', cursive;

}
#to-do-form input:focus, #to-do-form button:focus{
  outline: none;
}
#to-do-form button{
  height: 50px;
  width: 80px;
  border-radius: 5px;
  /* border: 0; */
  /* background-color: rgb(202, 156, 24); */
  background-color: blueviolet;
  cursor: pointer;
  font-weight: bolder;
  font-size: 20px;
  color: #fff;
  border: 3px solid blueviolet;
  font-family: 'Yeon Sung', cursive;
}
